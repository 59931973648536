import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AppBar from '@material-ui/core/AppBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import cn from 'classnames';
import SearchIconImg from '~images/navbar/search-icon.png';
import Link from '~components/Link';
import RoundedLinkButton from '~components/buttons/RoundedLinkButton/RoundedLinkButton';
import ABLogo from '~images/navbar/alephbeta-logo.svg';
import ABWhiteLogo from '~images/navbar/alephbeta-logo-white.svg';
import { getRegisterButtonProps } from '~utils/buttontext';
import { useTestId } from '~utils/set-testid';
import { getNavigationPageLink } from '~utils/common-site-helper';
import useConversionFramework from '~hooks/useConversionFramework';
import SearchIconWhiteImg from '~images/navbar/search-icon-white.svg';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import NavbarDrawer from './NavbarDrawer';

const useStyles = makeStyles(theme => ({
  root: props => ({
    paddingLeft: '40px',
    paddingRight: '40px',
    boxShadow: '0 -2px 3px 3px rgb(0 0 0 / 5%)',
    backgroundColor: props.isDark ? '#0F0F0F !important' : '#FFFFFF',
    [theme.breakpoints.up('md')]: {
      minHeight: '68px',
      display: 'grid'
    },
    [theme.breakpoints.down('md')]: {
      padding: '5px 30px'
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '10px',
      paddingRight: '10px'
    }
  }),
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  webContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px'
  },
  textLinkArea: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  searchLink: {
    color: '#000',
    lineHeight: 0,
    marginRight: 5,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  logo: {
    [theme.breakpoints.down('md')]: {
      marginLeft: '10px'
    },
    [theme.breakpoints.down('xs')]: {
      height: '30px'
    }
  },
  logoLink: {
    lineHeight: 0
  },
  linkText: props => ({
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'inherit',
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'uppercase',
    paddingLeft: '16px',
    paddingRight: '16px',
    minHeight: '68px',
    color: props.isDark ? '#FFFFFF' : '#000000',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: props.isDark ? '#222222' : '#f6f6f6'
    }
  }),
  highlightLinkText: props => ({
    color: props.isDark ? '#50A2F0 !important' : '#317DC9 !important',
    backgroundColor: props.isDark ? '#003F7A !important' : '#E1F0FF !important',
    border: props.isDark ? '2px solid #278AE7' : 'none',
    height: 'fit-content !important',
    borderRadius: '20px !important',
    alignSelf: 'center !important',
    padding: '10px 18px !important',
    marginLeft: '4px !important',
    marginRight: '4px !important',
    minHeight: 'unset !important',
    '&:hover': {
      textDecoration: 'none',
      border: props.isDark ? '2px solid #d5e5f6' : 'none',
      color: props.isDark ? '#d5e5f6 !important' : '#317DC9 !important',
      backgroundColor: props.isDark ? '#125089 !important' : '#d5e5f6 !important'
    }
  }),
  initialHighlightLinkText: {
    marginLeft: '20px !important'
  }
}));

const GuestNavbar = memo(({ items, showAbout }) => {
  const { isMarketingSite } = useConversionFramework();
  const { jwtAvailable, marketingUser } = useUserContext().session;
  const classes = useStyles({ isDark: isMarketingSite });
  const [showNavbarDrawer, setShowNavbarDrawer] = useState(false);
  const location = useLocation();
  const tryFreeTestId = useTestId('guest-header-try-free-btn');
  const signInTestId = useTestId('guest-header-signin-btn');
  const drawerMenuTestId = useTestId('guest-menu-drawer-btn');

  const { text, url, shouldTrackAnalytics, analyticsEvent } = getRegisterButtonProps(
    jwtAvailable,
    isMarketingSite,
    marketingUser
  );

  const handleOpenDrawer = () => {
    setShowNavbarDrawer(true);
  };

  const handleCloseDrawer = () => {
    setShowNavbarDrawer(false);
  };

  const gotoAbout = () => {
    const aboutPath = isMarketingSite && jwtAvailable ? '/welcome' : '/';
    navigate(aboutPath, { replace: true, state: { playIntroVideo: true } });
  };

  useEffect(() => {
    if (location) {
      handleCloseDrawer();
    }
  }, [location]);

  return (
    <>
      <AppBar
        component="nav"
        color="inherit"
        elevation={0}
        position="relative"
        className={classes.appBar}
      >
        <div className={classes.root}>
          <div className={classes.webContainer}>
            <div className={classes.logoContainer}>
              <Hidden lgUp>
                {!showNavbarDrawer && (
                  <IconButton
                    size="small"
                    aria-label="menu"
                    onClick={handleOpenDrawer}
                    {...drawerMenuTestId}
                  >
                    <MenuIcon
                      fontSize="large"
                      htmlColor={isMarketingSite ? '#FFFFFF' : '#000000'}
                    />
                  </IconButton>
                )}
                {showNavbarDrawer && (
                  <IconButton size="small" aria-label="close-menu" onClick={handleCloseDrawer}>
                    <CloseIcon
                      fontSize="large"
                      htmlColor={isMarketingSite ? '#FFFFFF' : '#000000'}
                    />
                  </IconButton>
                )}
              </Hidden>
              <Link to="/" className={classes.logoLink}>
                <img
                  src={isMarketingSite ? ABWhiteLogo : ABLogo}
                  alt="Aleph Beta Logo"
                  className={classes.logo}
                />
              </Link>
            </div>

            <div className={classes.linkContainer}>
              <div className={classes.textLinkArea}>
                <Link to="/search" className={classes.searchLink}>
                  <img
                    src={isMarketingSite ? SearchIconWhiteImg : SearchIconImg}
                    alt="Aleph Beta Search"
                    width={32}
                    height={32}
                  />
                </Link>
                {items.map((link, index) => (
                  <Link
                    key={link.link_title}
                    to={link.link_url}
                    className={cn(
                      link.highlight && index === 0 && classes.initialHighlightLinkText,
                      link.highlight && classes.highlightLinkText,
                      classes.linkText
                    )}
                  >
                    {link.link_title}
                  </Link>
                ))}
                {showAbout && (
                  <ButtonBase onClick={gotoAbout} className={classes.linkText} disableRipple>
                    ABOUT
                  </ButtonBase>
                )}

                {!jwtAvailable && (
                  <Link
                    to={getNavigationPageLink('/login', isMarketingSite)}
                    className={classes.linkText}
                    {...signInTestId}
                  >
                    LOG IN
                  </Link>
                )}
              </div>
              <RoundedLinkButton
                text={text}
                to={url}
                shouldTrackAnalytics={shouldTrackAnalytics}
                analyticsEvent={analyticsEvent}
                analyticsTitle="Header"
                analyticsSection="Guest Navbar"
                size="mini"
                variant="blue"
                {...tryFreeTestId}
              />
            </div>
          </div>
        </div>
      </AppBar>
      <Hidden lgUp>
        <NavbarDrawer
          open={showNavbarDrawer}
          onClose={handleCloseDrawer}
          onAboutClick={gotoAbout}
          items={items}
          showAbout={showAbout}
        />
      </Hidden>
    </>
  );
});

GuestNavbar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link_title: PropTypes.string.isRequired,
      link_url: PropTypes.string.isRequired,
      highlight: PropTypes.bool
    })
  ),
  showAbout: PropTypes.bool
};
GuestNavbar.defaultProps = {
  items: [],
  showAbout: false
};

export default GuestNavbar;
